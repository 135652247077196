// Entrypoint for stimulus
// Add the following line of code to the .erb file that you want to use Stimulus in:
// <% append_javascript_pack_tag("stimulus") %>

import { Application } from "@hotwired/stimulus";

import DestroyOTPController from "./controllers/destroy_otp_controller";
import EditPasswordsController from "./controllers/edit_passwords_controller";
import LoginController from "./controllers/login_controller";
import NewPasswordsController from "./controllers/new_passwords_controller";
import OTPFormController from "./controllers/otp_form_controller";
import SetWellnessPasswordsController from "./controllers/set_wellness_passwords_controller";
import SignupController from "./controllers/signup_controller";
import UserSettingsController from "./controllers/user_settings_controller";

const application = Application.start();
// Configure Stimulus development experience
application.debug = false;
// window.Stimulus   = application

application.register("destroy-otp", DestroyOTPController);
application.register("edit-passwords", EditPasswordsController);
application.register("login", LoginController);
application.register("new-passwords", NewPasswordsController);
application.register("otp-form", OTPFormController);
application.register("set-wellness-passwords", SetWellnessPasswordsController);
application.register("signup", SignupController);
application.register("user-settings", UserSettingsController);
